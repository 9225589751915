import styled from 'styled-components';
export const ClearFix = styled.div`
  :after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;
export const HomeSplit = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  @media (max-width: 767px) {
    display: block;
  }
`;

export const HomeSplitFoundation = styled.div`
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 1;
  text-align: center;
  padding: 120px 135px 120px 135px;
  background: ${(props) => `url(${props.$imageUrl}) no-repeat top center`};
  @media (max-width: 1229px) {
    padding: 80px 40px;
    background-position: center -100px;
  }
  @media (max-width: 1023px) {
    flex-basis: 100%;
    padding: 75px 50px;
    background-position: top center;
  }
  @media (max-width: 767px) {
    padding: 45px 35px;
  }
`;

export const HomeSplitFoundationImage = styled.img`
  width: 294px;
  height: auto;
  margin: 0 0 60px 0;
  @media (max-width: 1829px) {
    width: 220px;
  }
  @media (max-width: 1299px) {
    width: 175px;
  }
  @media (max-width: 1023px) {
    width: 250px;
  }
`;

export const HomeSplitTitle = styled.h2`
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #00539d;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    margin-bottom: 20px;
    br {
      display: none;
    }
  }
`;

export const HomeSplitHSE = styled.div`
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 1;
  text-align: center;
  padding: 120px 100px 120px 100px;
  @media (max-width: 1199px) {
    padding: 75px 50px;
  }
  @media (max-width: 991px) {
    flex-basis: 100%;
    padding: 75px 50px;
  }
  @media (max-width: 767px) {
    padding: 45px 35px 10px 35px;
  }
`;
export const DedicatedHSEImages = styled.img`
  width: auto;
  height: 230px;
  margin: 50px 0 74px 0;
  &:nth-child(2) {
    margin-right: 30px;
  }
  @media (max-width: 1829px) {
    height: 140px;
  }
  @media (max-width: 1299px) {
    height: 110px;
  }
  @media (max-width: 1023px) {
    height: 80px;
    margin-top: 0;
    &:nth-child(1) {
      margin-right: 0;
    }
    &:nth-child(2) {
      margin-right: 10px;
    }
    &:nth-child(3) {
      margin-right: 0;
    }
  }
  @media (max-width: 767px) {
    height: 80px;

    margin-bottom: 35px;
    &:nth-child(2) {
      margin-right: 15px;
    }
  }
`;
export const DedicatedHSEButtonStyle = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 15px 35px;
  margin-right: 10px;
  font-family: 'MrAlex', sans-serif;
  font-size: 24px;
  line-height: 28px;
  color: #00a6e8;
  border: 2px solid #00a6e8;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
  border-radius: 100px;
  cursor: pointer;

  @media (max-width: 1439px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 1329px) {
    font-size: 25px;
    line-height: 25px;
    &:before {
      top: 17px;
    }
    &:after {
      width: 130px;
      height: 50px;
    }
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 25px;
    &:before {
      top: 17px;
    }

    &:after {
      width: 90px;
      height: 50px;
    }
  }

  :hover {
    top: -5px;
    color: #f7991c;
    border-color: #f7991c;
    box-shadow: 0 5px 0 0 #f7991c;
    @media (max-width: 1439px) {
      top: -5px;
      color: #f7991c;
      border-color: #f7991c;
      box-shadow: 0 5px 0 0 #f7991c;
    }
  }
`;

export const DedicatedHSEParagraph = styled.p`
  font-size: 20px;
  line-height: 32px;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  color: rgba(0, 0, 0, 0.6);
  @media (max-width: 1439px) {
    font-size: 18px;
    line-height: 28px;
  }
`;
